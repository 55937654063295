<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  export default {
    mounted() {
      console.log('process');
    },
  };
</script>

<style lang="scss">
 html  body{
  background: #F5F5F5;
  margin: 0;
  height: 100%;
  padding: 0;
}

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #F5F5F5;
  }

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
