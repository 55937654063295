const city = [{
  "value": "41",
  "label": "河南省",
  "children": [ {
    "value": "4103",
    "label": "洛阳市",
    "children": [{
      "value": "410302",
      "label": "老城区"
    }, {
      "value": "410303",
      "label": "西工区"
    }, {
      "value": "410304",
      "label": "瀍河回族区"
    }, {
      "value": "410305",
      "label": "涧西区"
    }, {
      "value": "410306",
      "label": "吉利区"
    }, {
      "value": "410311",
      "label": "洛龙区"
    }, {
      "value": "410322",
      "label": "孟津县"
    }, {
      "value": "410323",
      "label": "新安县"
    }, {
      "value": "410324",
      "label": "栾川县"
    }, {
      "value": "410325",
      "label": "嵩县"
    }, {
      "value": "410326",
      "label": "汝阳县"
    }, {
      "value": "410327",
      "label": "宜阳县"
    }, {
      "value": "410328",
      "label": "洛宁县"
    }, {
      "value": "410329",
      "label": "伊川县"
    }, {
      "value": "410371",
      "label": "洛阳高新技术产业开发区"
    }, {
      "value": "410381",
      "label": "偃师市"
    }]
  } ]
}

];
export default city;
