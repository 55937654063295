import axios from "axios";

import { Message } from 'element-ui'
// 'http://192.168.1.108:48091/app-api'
// http://admin-xiaofu.newjunhe.com/xiaofu/app-api


const request = axios.create({
  baseURL:'https://huayifuzhuang.cn/xiaofu/app-api',
  timeout: 50000,
});
// 3.拦截器==》请求拦截
request.interceptors.request.use(
  config => {
    const token = localStorage.getItem('userinfo') ? localStorage.getItem('userinfo') : false
    if (token) config.headers.Authorization = token
    return config
  },
  err => err
)
// 4.拦截器===》响应拦截
request.interceptors.response.use(
  config => {
    console.log(config, 'config22')
    const { data } = config
    // 无效token的处理
    if (data.code === 401) {
      localStorage.removeItem('userinfo')
      window.location.reload()
    }
    return Promise.resolve(data)
  },
  err => {
    // 服务器连接失败 Network Error  请求超时 timeout of 1ms exceeded
    console.log(err, 'err35')
    callBackError(err.code)

  })
function callBackError (msg) {
  const errorObj = {
    ECONNABORTED: '请求超时，稍后在操作',
    ERR_NETWORK: '网络错误，刷新在尝试'

  }
  // 设置默认值
  const info = errorObj[msg] || '未知错误，刷新后重试'
  Message.warning(info)
}


export default request

