import router from '../router'
// import store from '../store'
import { Message } from 'element-ui'

const pp = ['/login']
router.beforeEach((to, from, next) => {
  console.log(pp.includes(to.path), 'jjb')
  if (pp.includes(to.path)) return next()
  const token = localStorage.getItem("userinfo")
  if (token) {
    if (hasRouter(to)) return next()
    return
  }
  Message.warning('请重新登录在访问！')
  next('/login')
})
function hasRouter ({ name }) {
  const bool = router.getRoutes().some(item => item.name === name)
  return bool
}

