import request from '@/utils/request'
export const userget =()=>{
  return  request.get('/app/myInfo/getInfo')
}

export const pagePhase = (params) => {
	return request.get('/app/myInfo/page-phase', {params})
};
export const pageSchool = (params) => {
	return request.get('/app/myInfo/page-school',{params})
};

export const pageCampus = (params) => {
	return request.get('/app/myInfo/page-campus',{params})
};
export const pageClassify = (params) => {
	return request.get('/app/myInfo/page-classify',{params})
};

export const dictData = (params) => {
	return request.get('/app/myInfo/page-grade',{params})
};

export const update = (data) => {
	return request.put('/member/user/update',data)
};
export const userxieyi = (data) => {
	return request.get('/app/myInfo/get-agreement?id='+data)
};
