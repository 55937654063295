import request from '@/utils/request'

const exportEx = (data, fileName) => {
  const blob = new Blob([data], { type: "application/vnd.ms-zip" });
  const URL = window.URL || window.webkitURL;
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
}

export const orderPage = (params) => {
  return request.get('/app/order/page', { params })
}
export const exportExcel = (params) => {
  return request.get('/app/order/export-order-info', { params,responseType:'blob' }).then(res=>{
    exportEx(res,'xiaofu.zip')
  })
}
