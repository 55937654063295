<template>
  <div class="header">
    <div class="center">
      <div class="lefttitle">校服售后PC端</div>
      <div class="right">
        <img src="/assets/1-1.8@2x.png" alt="">
        <span class="lefttitle" @click="outlogin">退出</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
methods:{
  outlogin(){
    this.$message({
          message:'退出成功',
          type: "success",
        });
      localStorage.clear();
     this.$router.push('/login')
  }
}

};
</script>

<style scoped>
.header {
  width: 100%;
  overflow: hidden;
  background: #ffffff;
}
.center {
  width: 1860px;
  margin: auto !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lefttitle {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  cursor: pointer;
}
.right{

  display: flex;
  align-items: center;
}
.right img{
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
</style>
