import request  from '@/utils/request';



export const uniformSchool = (params) => {
	return request.get('/app/myInfo/page-classify', {params})
};
// 获得类型分页
export const pageType = (params) => {
	return request.get('/app/myInfo/page-type',{params})
};
// 获得部位分页
export const pagePosition = (params) => {
	return request.get('/app/myInfo/page-position',{params})
};
// 获得尺码分页
export const pageSize = (params) => {
	return request.get('/app/myInfo/page-size',{params})
};
// 创建订单详情
export const createorder = (data) => {
	return request.post('/app/order-info/create',data)
};
//  获得订单详情分页
export const orderInfo = (params) => {
	return request.get('/app/order-info/page',{params})
};

export const creat = (data) => {
	return request.post('/app/order/create',data)
};
export const getinfo = (data) => {
	return request.get('/app/order-info/get',data)
};
export const deleteorder = (data) => {
	return request.delete('/app/order/delete?id='+data)
};
export const removeorider = (data) => {
	return request.delete('/app/order-info/delete?id='+data)
};
export const updateorder = (data) => {
	return request.put('/app/order-info/update',data)
};

export const getorder = (id) => {
	return request.get('/app/order/get?id='+id)
};
export const batchCreate = (data) => {
	return request.post('/app/order-info/batch-create',data)
};

export const cancel = (id) => {
	return request.get('/app/order/cancel?id='+id)
};

export const updateStatus= (data) => {
	return request.put('/app/order/update-status', data)
};
export const schoolimg= (params) => {
	return request.get('/app/myInfo/page-uniform-school', {params})
};