<template>
  <div class="home">
    <headerView></headerView>
    <div class="nav">
      <menua @editinformation="editinformation1" :userobj="userobj"></menua>
      <div class="rightcontent">
        <div class="options">
          <div
            class="options_item"
            v-for="(item, index) in arr"
            :key="index"
            @click="Itemclick(item.title)"
          >
            <img :src="item.src" alt="" class="options_item_img" />
            <div class="name">{{ item.title }}</div>
          </div>
        </div>

        <div class="classfy">
          <div
            :class="active == index ? 'classfyitem active' : 'classfyitem'"
            v-for="(item, index) in arr"
            :key="index"
            @click="clickstauts1(index)"
          >
            {{ item.title }}记录
          </div>
        </div>
        <div class="region">
          <div class="leftregion">
            <el-form
              :model="ruleForm"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="订单ID" prop="name">
                <el-input
                  v-model="query.id"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                  placeholder="请输入订单ID"
                ></el-input>
              </el-form-item>
              <el-form-item label="订单编号" prop="name">
                <el-input
                  v-model="query.orderNum"
                  placeholder="请输入订单编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="订单状态" prop="region">
                <el-select v-model="query.status" placeholder="全部">
                  <el-option label="已取消" value="2"></el-option>
                  <el-option label="备货中" value="3"></el-option>
                  <el-option label="送货中" value="4"></el-option>
                  <el-option label="已完成" value="5"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间" required>
                <el-col :span="11">
                  <el-form-item prop="date1">
                    <el-date-picker
                      v-model="createTime"
                      type="daterange"
                      range-separator="~"
                      @change="changea"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-form>
          </div>
          <div class="rightregion">
            <el-button type="primary" @click="orderinit">搜索</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </div>
        <div class="orgirbox">
          <div class="orgirclassify">
            <div
              :class="index == active1 ? 'text active1' : 'text'"
              v-for="(item, index) in arr1"
              @click="clickstauts(index)"
            >
              {{ item }}
            </div>
            <el-button type="primary" @click="daochu" class="daochu" style="float: right"
              >导出</el-button
            >
          </div>
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="id" label="订单ID" width="180"> </el-table-column>
            <el-table-column prop="orderNum" label="订单编号" width="180">
            </el-table-column>
            <!-- <el-table-column prop="quantity" label="已调换数量"> </el-table-column> -->
            <el-table-column label="已调换数量">
              <template slot-scope="scope">
                x{{ scope.row.quantity}}
              </template>
            </el-table-column>
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                {{ statustext(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                {{ getNowFormatDate(scope.row.createTime) }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small"
                  >查看</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  style="color: #666666"
                  v-if="scope.row.status == 5"
                  @click="remove(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagefen">
          <el-pagination
            :background="isBackground"
            layout="prev, pager, next"
            @current-change="currentChange"
            :total="total"
            :page-size="query.pageSize"
            class="msg-pagination-container"
            @getlist="getlist"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <UserForm
      @handleClose="handleClose"
      :dialogVisible="dialogVisible"
      :title="title"
      @getlist="getlist"
      :userobj="userobj"
      @getuser="getuser"
      v-if="dialogVisible"
    ></UserForm>
  </div>
</template>

<script>
// @ is an alias to /src
import headerView from "@/components/header.vue";
import menua from "@/components/menu.vue";
import UserForm from "./UserForm.vue";
import { userget } from "@/api/my";
import { orderPage, exportExcel } from "@/api/home.js";
import { deleteorder } from "@/api/xiaofu";
export default {
  name: "HomeView",
  components: {
    headerView,
    menua,
    UserForm,
  },
  data() {
    return {
      value1: "",
      query: {
        status: "",
        type: 0,
        pageNo: 1,
        pageSize: 8,
        id: "",
        orderNum: "",
        status: "",
        createTime: [],
        createTimeFirst: "",
        createTimeLast: "",
        // updateTime:''
      },
      alllist:[],
      createTime: "",
      active1: 0,
      pageNo: 1,
      isBackground: true,
      total: 0,
      userobj: {},
      dialogVisible: false,
      title: "编辑学校信息",
      active: 0,
      arr1: ["全部订单", "备货中订单", "送货中订单", "已完成订单", "已取消订单"],
      tableData: [],
      arr: [
        { title: "校服调换", src: "/assets/1-1.1@2x.png" },
        { title: "校服新增", src: "/assets/1-1.2@2x.png" },
        { title: "校服退订", src: "/assets/1-1.3@2x.png" },
        { title: "校服补发", src: "/assets/1-1.4@2x.png" },
      ],
      ruleForm: {},
    };
  },
  watch: {
    query(xin) {
      if (xin["status"] && xin["createTime"] && xin["orderNum"] && xin["id"]) {
        this.orderinit();
      }
    },
  },
  created() {
    this.orderinit();
    this.myinfo();
  },
  methods: {
    handleSelectionChange(val) {
        // this.multipleSelection = val;
        console.log(val,219);
        this.alllist=val
      },
    exportEx (data, fileName){
  const blob = new Blob([data], { type: "application/vnd.ms-doc" });
  const URL = window.URL || window.webkitURL;
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
},

    async daochu() {
      if( !this.alllist.length){
        this.$message({
          message: '请选中导出表格内容',
          type: "warning",
        });
        return
      }
      var ids =this.alllist.map(item=>item.id)
      const res =await exportExcel({
      'ids':ids.join('-')
      })
    },

    getuser() {
      this.myinfo();
      this.dialogVisible = false;
    },
    resetForm() {
      // this.query.status=''
      this.query.orderNum = "";
      this.query.createTime = [];
      this.query.createTimeFirst = "";
      this.query.createTimeLast = "";
      this.createTime = "";
      this.query.id = "";
      this.query.status = "";

      this.value1 = "";
      this.active1 = 0;
      this.orderinit();
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    async remove(id) {
      const res = await deleteorder(id);
      if (res.code == 0) {
        this.$message.success("删除成功");
        this.orderinit();
      } else {
        this.$message.warning(res.msg);
      }
    },
    getlist() {
      this.orderinit();
      this.dialogVisible = false;
    },

    Itemclick(e) {
      this.title = e;
      this.dialogVisible = true;
    },
    clickstauts1(index) {
      console.log(index, 186);
      this.active = index;
      if (!index) {
        this.query.status = "";
        this.query.type = 0;
      } else if (index == 1) {
        this.query.status = "";
        this.query.type = 1;
      } else if (index == 2) {
        this.query.status = "";
        this.query.type = 2;
      } else if (index == 3) {
        this.query.status = "";
        this.query.type = 3;
      }
      this.orderinit();
    },
    clickstauts(index) {
      console.log(index, 186);
      this.active1 = index;
      if (!index) {
        this.query.status = "";
      } else if (index == 1) {
        this.query.status = 3;
      } else if (index == 2) {
        this.query.status = 4;
      } else if (index == 3) {
        this.query.status = 5;
      } else if (index == 4) {
        this.query.status = 2;
      }
      this.orderinit();
    },
    statustext(status) {
      if (!status) {
        return "已取消";
      } else {
        if (status == 2) {
          return "已取消";
        } else if (status == 3) {
          return "备货中";
        } else if (status == 4) {
          return "送货中";
        } else if (status == 5) {
          return "已完成";
        }
      }
    },

    currentChange(e) {
      this.query.pageNo = e;
      this.orderinit();
    },
    async myinfo() {
      const res = await userget();
      console.log(res);
      if (res.code == 0) {
        this.userobj = res.data;
      }
    },
    async orderinit() {
      console.log(this.query, "this.querythis.query");

      const res = await orderPage(this.query);
      if (res.code == 0) {

        this.tableData = res.data.list;
        this.total = res.data.total;
        console.log(res.data, "res.total");
      }
    },
    changea(e) {
      console.log(e, 308);
      this.query.createTimeFirst = this.formatDate(new Date(e[0]));
      this.query.createTimeLast = this.formatDate(new Date(e[1]));
      console.log(
        this.query.createTime,
        " this.query.createTime this.query.createTime this.query.createTime this.query.createTime this.query.createTime this.query.createTime this.query.createTime this.query.createTime"
      );
    },
    handleClose() {
      console.log(173);

      this.dialogVisible = false;
    },
    handleClick(e) {
      console.log(123, e);
      this.$router.push({ path: "/detail", query: { id: e.id } });
    },
    editinformation1() {
      console.log(176);
      this.dialogVisible = true;
      this.title = "编辑学校信息";
    },
    getNowFormatDate(time) {
      //获取当前时间
      var date = new Date(time);
      var seperator1 = "-";
      var seperator2 = ":";
      var month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        date.getHours() +
        seperator2 +
        date.getMinutes() +
        seperator2 +
        date.getSeconds();
      return currentdate;
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  width: 100%;
  overflow: hidden;
}
.nav {
  width: 1880px;
  margin: auto !important;
  margin-top: 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightcontent {
  width: 1620px;
  overflow: hidden;
  height: 1080px;
}
.options {
  width: 1620px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_item {
    width: 370px;
    height: 130px;
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &_img {
      width: 370px;
      height: 130px;
      position: absolute;
      z-index: 0;
    }
    .name {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      z-index: 999;
      margin-top: 52px;
    }
  }
}
.classfy {
  width: 100%;
  margin-top: 21px;
  height: 50px;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  align-items: center;
}
.classfy .classfyitem:first-child {
  margin-left: 20px;
}
.classfyitem {
  width: 150px;
  height: 46px;

  border-radius: 16px 16px 0px 0px;
  margin-right: 79px;
  text-align: center;
  line-height: 46px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 18px;
  color: #2f7aff;
  margin-top: 4px;
  cursor: pointer;
}
.active {
  background: #2f7aff;
  color: #ffffff;
}
.active1 {
  color: #2f7aff !important;
}
.region {
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  margin-top: 20px;
  padding: 16px 0;
  .leftregion {
    margin-left: 23px;
    overflow: hidden;
  }
  form {
    width: 924px;
    display: flex;
    flex-wrap: wrap;
  }
}
.rightregion {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.orgirbox {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 10px;
}
.orgirclassify {
  width: 100%;
  display: flex;
  margin-bottom: 18px;
}
.orgirclassify .text {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-left: 59px;
  cursor: pointer;
}
.orgirclassify .text:first-child {
  margin-left: 0 !important;
}
.el-table >>> tr {
  background: #f5f5f5 !important;
}
.pagefen {
  margin-top: 36px;
  float: right;
}

.msg-pagination-container.is-background .el-pager li {
  /*对页数的样式进行修改*/
  background-color: #fff;
  color: #fff;
}
.msg-pagination-container.is-background .btn-next {
  /*对下一页的按钮样式进行修改*/
  background-color: #fff;
  color: #fff;
}
.msg-pagination-container.is-background .btn-prev {
  /*对上一页的按钮样式进行修改*/
  background-color: #fff;

  color: #fff;
}
.msg-pagination-container.is-background .el-pager li:not(.disabled).active {
  /*当前选中页数的样式进行修改*/
  background-color: #446cff;
  color: #fff;
}

.daochu {
  width: 88px;
  height: 32px;
  background: #2f7aff;
  border-radius: 6px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 653px;
}
</style>
