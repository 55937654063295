<template>
  <div class="box">
    <img :src="userobj.avatar" alt="" class="avtar" />
    <div class="name">{{userobj.nickname}}</div>
    <div class="schoolinfo">
      <span>{{ userobj.gradeName }}</span>
      <div class="readuce"></div>
      <span>{{ userobj.className }}</span>
      <div class="readuce"></div>

      <span>{{ userobj.name }}</span>
    </div>
    <div class="schoolnam" style="margin-top: 20px;">{{ userobj.schoolName }}</div>
    <div class="schoolnam">{{userobj.campusName }}</div>
    <div  class="button" @click="compile">编辑学校信息</div>
  </div>
</template>

<script>

export default {
  props:['userobj'],
  methods:{
    compile(){
      this.$emit('editinformation','编辑学校信息')
    }
  }
};
</script>

<style scoped>
.box {
  width: 240px;
  overflow: hidden;
  height: 1080px;
  background: #fff;
}
.box img {
  width: 80px;
  height: 80px;
  margin: auto;
  margin-top: 30px;
}
.name {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  text-align: center;
  margin-top: 20rpx;
}
.schoolinfo {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 20rpx;
}
.schoolinfo span {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.schoolnam {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #aaaaaa;
  text-align: center;
}
.button {
  width: 106px;
  height: 32px;
  background: rgba(47, 122, 255, 0.12);
  border-radius: 6px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #2f7aff;
  line-height: 32px;
  text-align: center;
  margin: auto;
  margin-top: 50px;
  cursor: pointer;
}
.readuce{
  width: 5px;
  height: 5px;
  background: #2F7AFF;
  border-radius: 50%;
margin-left: 5px;
margin-right: 5px;
}
.avtar{
  width: 80px;
height: 80px;
background: #2F7AFF;
border-radius: 50%;
}
</style>
